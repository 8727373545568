@import '../../../../../app_diesel_revamp/cartridge/client/default/scss/utils/mixins';
@import '../../../../../app_diesel_revamp/cartridge/client/default/scss/utils/normalize';
@import '../../../../../app_diesel_revamp/cartridge/client/default/scss/variables';
@import '../../../../../app_diesel_revamp/cartridge/client/default/scss/common';
@import '../../../../../app_diesel_revamp/cartridge/client/default/scss/helpers/helpers';

@import "choices.js/src/styles/choices";

body {
  font-family: BananaGrotesk, sans-serif;
  font-weight: 500;
  font-style: normal;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
}

.dropdown-container {
  width: 100%;
}

select {
  display: none;
}

.dsl-custom-select[data-type*=select-one] {
  margin-bottom: 0.8rem;
  border-radius: 0;
  color: var(--text-color);

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 40px;
    height: 40px;
    background: url(map-get($sprites, 'Chevron-Down')) center no-repeat;
    border: none;
    border-left: 1px solid var(--border-color);
    background-size: 24px;
    transform: translateY(-50%);
    pointer-events: none;
    margin-top: 0;
  }

  &.is-open {
    &::after {
      margin-top: 0;
      border-left: 1px solid var(--border-color);
    }
  }

  .choices__inner {
    height: 40px;
    min-height: 40px;
    border-radius: 0;
    border: 1px solid var(--border-color);
  }

  .choices__list--dropdown {
    border: 1px solid var(--border-color);
    border-radius: 0;

    .choices__item {
      &.is-selected {
        &.is-highlighted {
          background-color: white;
        }
        &::after {
          opacity: 1;
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 1.2rem;
          width: 20px;
          height: 20px;
          background: url(map-get($sprites, 'check-red')) no-repeat center;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }
    }
  }
}

.go-btn {
  width: 100%;
  height: 4rem;
  background-color: var(--main-color);
  color: var(--white);
  outline: none;
  border: none;
  font-size: 1.4rem;
  cursor: pointer;

  &:disabled {
    background-color: #F7B2BF;
    cursor: not-allowed;
  }
}

.header__logo {
  width: 180px;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 100;
  max-width: 420px;
  transition: width ease-in-out .4s;

  img {
    display: block;
    width: 100%;
    height: auto;
    will-change: transform;
    transition: opacity 150ms ease;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: initial;
  }
}

@media (min-width: 992px) {
  .header__logo {
    width: 25vw;
    max-width: 360px;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
  }

  .header__logo::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    padding-top: 55%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity 0.6s linear;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: start;
    padding-left: 10rem;
    width: 100%;
  }

  .dropdown-container {
    width: 40rem;
    padding-right: 1rem;
  }

  .go-btn {
    width: 29rem;
  }
}